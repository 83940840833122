import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Outage from './components/Outage';
import LoadingSpinner from 'components/common/LoadingSpinner';
import { LocalizationContext } from 'services/localization';
import './styles/home.scss';

export default function Home({
  locale,
  settings
}) {
  const localized = useContext(LocalizationContext);
  const { showOutagePage } = settings;

  useEffect(() => {
    // TODO: Once French is implemented, do routing based on locale
    if (!showOutagePage) {
      window.location.href = 'https://embark.ca/resp-gifting';
    };
  }, {});

  return (
    <div className="home">
      {showOutagePage ? (
        <Outage
          text={settings && settings.outagePage || {}}
          locale={locale}
          localized={localized}
        />
      ) : <LoadingSpinner /> }
    </div>
  );
}

Home.propTypes = {
  locale: PropTypes.string,
  location: PropTypes.shape({
    query: PropTypes.object
  }),
  settings: PropTypes.object
};
