/* eslint-disable max-len */
export default {
  'embark': 'Embark',
  'embarkStudentCorp': 'Embark Student Corp.',
  'home.footerCopyrightSymbol': '©',
  'home.footerAllRightsReserved': 'All rights reserved.',
  'home.footerTrademarkText': 'Embark is a trademark of Embark Student Corp.',
  'outage.title': 'We will be back online soon.',
  'outage.message': 'Our site is currently down for maintenance. Exciting upgrades are coming!',
  'register': 'Register',
  'continue': 'Continue',
  'next': 'Next',
  'send': 'Send',
  'retry': 'Retry',
  'done': 'Done',
  'cancel': 'Cancel',
  'copy': 'Copy',
  'copied': 'Copied!',
  'update': 'Update',
  'skip': 'Skip',
  'and': 'and',
  'note': 'Note:',
  'his': 'his',
  'her': 'her',
  'their': 'their',
  'on': 'on',
  'is': 'is',
  'disagree': 'Disagree',
  'decline': 'Decline',
  'name': 'Name',
  'change': 'Change',
  'remove': 'Remove',
  'noKeep': 'No, keep',
  'yesRemove': 'Yes, remove',
  'share': 'Share',
  'close': 'Close',
  'confirmRemove': 'Are you sure you want to remove?',
  'giftDetails.title': 'Gift amount',
  'giftDetails.banner.title': 'How much do you want to gift?',
  'giftDetails.banner.description': 'Choose the amount you’d like to send. You can gift as little as $25, and up to a maximum of $2,500.',
  'giftDetails.amount.input.label': 'How much would you like to gift?',
  'giftDetails.amount.input.textFieldLabel': 'Custom amount',
  'giftDetails.message.input.label': 'Add a message (optional)',
  'giftDetails.message.input.placeholder': 'A little something for you. Wishing you success and joy ahead!',
  'giftDetails.about.label': 'Send a gift note',
  'giftDetails.about.description': 'Let them know you’re thinking about them with a customized message.',
  'giftDetails.options.custom': 'Custom',
  'giftDetails.error.amountEmpty': 'Please select an option to continue.',
  'giftDetails.error.amountUnderMin': 'Enter a minimum of $25.',
  'giftDetails.error.amountOverMax': 'Enter a maximum of $2,500.',
  'giftDetails.error.exceededMaximumCharacter': 'Gift note must be 1000 characters or less.',
  'giftDetails.error.allFieldsRequired': 'Please complete all details to continue',
  'giftCompliance.title': 'We need a few details',
  'giftCompliance.banner.title': 'What’s your relationship?',
  'giftCompliance.banner.description': 'We ask for this information to ensure that we are in compliance with government guidelines for Registered Education Savings Plans (RESPs).',
  'giftCompliance.relationship.heading': 'What’s your relationship to the child?',
  'giftCompliance.relationship.dropdown.label': 'Relationship',
  'giftCompliance.relationship.dropdown.grandparent': 'Grandparent',
  'giftCompliance.relationship.dropdown.aunt': 'Aunt',
  'giftCompliance.relationship.dropdown.uncle': 'Uncle',
  'giftCompliance.relationship.dropdown.sibling': 'Sibling',
  'giftCompliance.relationship.dropdown.parent': 'Parent',
  'giftCompliance.relationship.dropdown.notRelated': 'Not related',
  'giftCompliance.relationship.dropdown.other': 'Other',
  'giftCompliance.error.noRelationship': 'Please select an option to continue.',
  'giftError.title.expired': 'Something’s not right',
  'giftError.description.expired': 'It appears that the gifting link has expired. Please ask the subscriber to create a new link.',
  'facebook': 'Facebook',
  'x': 'X',
  'whatsApp': 'WhatsApp',
  'reddit': 'Reddit',
  'linkedIn': 'LinkedIn',
  'img.alt.annulus': 'A ring shape cut in half',
  'img.alt.schoolPin': 'Icon of a graduation cap',
  'img.alt.clippedSquarePattern': 'Small square',
  'img.alt.smallCirclePattern': 'Small circle',
  'img.alt.largeCirclePattern': 'Large circle',
  'img.alt.halfCirclePattern': 'Half circle',
  'img.alt.extraLargeCirclePattern': 'Extra large circle',
  'img.alt.diamondPattern' : 'Diamond',
  'img.alt.noSearchOptionsIllustration': 'Illustration of an antenna receiving no signal',
  'img.alt.professionsSearchIllustration' : 'Illustration of a blue person browsing the internet',
  'img.alt.professionsSearchRocketTelescope': 'Illustration of a blue person looking through telescope wearing a jetpack',
  'img.alt.emailIcon': 'Email',
  'img.alt.searchIcon': 'Search',
  'img.alt.closeIcon': 'Close',
  'img.alt.arrowBackIcon': 'Back arrow',
  'img.alt.programScholarIcon': 'Icon of magnifying glass searching through paper',
  'img.alt.sinDrivingLicense': 'SIN Driving License',
  'img.alt.successIcon': 'Success Icon',
  'img.alt.apolloIcon': 'Company icon of Apollo',
  'img.alt.youthfullyIcon': 'Company icon of Youthfully',
  'img.alt.willfulIcon': 'Company icon of Willful',
  'img.alt.borrowellIcon': 'Company icon of Borrowell',
  'img.alt.neoFinancialIcon': 'Company icon of NeoFinancial',
  'img.alt.policyMeIcon': 'Company icon of PolicyMe',
  'img.alt.facebookIcon': 'Company icon of Facebook',
  'img.alt.xIcon': 'Company icon of X',
  'img.alt.whatsAppIcon': 'Company icon of WhatsApp',
  'img.alt.redditIcon': 'Company icon of Reddit',
  'img.alt.linkedInIcon': 'Company icon of LinkedIn',
  'img.alt.specialization': 'Notify',
  'img.alt.btnPlusIcon': 'Plus Icon',
  'img.alt.btnTransferIcon': 'Transfer Icon',
  'img.alt.trendingUpIcon': 'Trending icon',
  'img.alt.iq.yellowArrow': 'yellow arrow with no tail',
  'img.alt.iq.thumbsUp': 'Thumbs up',
  'img.alt.iq.thumbsDown': 'Thumbs Down',
  'img.alt.giftLinkError': 'Link error',
  'img.alt.giftCircle': 'Small present box',
  'img.alt.shieldIcon': 'Shield',
  'img.alt.embarkGift': 'Embark Gifting',
  'gifting.about.headerOne': 'You’ve been invited to gift to',
  'gifting.about.headerTwo': 'RESP',
  'gifting.about.bodyOne': 'Your gift to',
  'gifting.about.bodyTwo': 'RESP will play a crucial role in shaping their future. It will support their educational aspirations, empowering them to achieve their dreams.',
  'gifting.about.button': 'Gift now',
  'gifting.list.header': 'About Embark Gifting',
  'gifting.list.one.header': 'Gifting is safe and secure',
  'gifting.list.one.body': 'You can securely and conveniently gift using Credit Cards, Apple Pay and Google Pay. Everything is encrypted and safe.',
  'gifting.list.two.header': 'A gift that keeps on giving',
  'gifting.list.two.body': 'You can gift up to $2,500, and your gift could qualify for an extra 20% in government grants. Your gift is highly appreciated, but please note that this gift is not considered a donation, and as a result, gifters will not receive tax receipts.',
  'gifting.list.three.header': 'Your relationship',
  'gifting.list.three.body': 'We need to ask you some questions about your relationship with the gift recipient to comply with government regulations.',
  'gifting.about.terms.body': 'By sending a gift to an Embark child, you are consenting to the',
  'gifting.about.terms.link': 'Terms and conditions',
  'gifting.about.terms.alt': 'redirect icon',
  'gifting.review.header': 'Let’s quickly review',
  'gifting.review.name': 'Embark gift recipient:',
  'gifting.review.amount': 'Embark RESP gift total:',
  'gifting.review.processingFee': 'Processing fee:',
  'gifting.review.total': 'Total amount:',
  'gifting.review.message': 'Gift message:',
  'gifting.review.button': 'Pay and send gift',
  'gifting.review.dialog.header': 'Processing fee',
  'gifting.review.dialog.body': 'The bank that issues the payment card charges a fee to cover the service of handling the transaction. We don’t make any money from processing fees and do our best to keep them as low as possible.',
  'footer.embark': 'Embark.',
  'footer.rightsReserved': 'All rights reserved.',
  'footer.trademarkLine2': 'Embark is a trademark of Embark Student Corp.',
  'networkError.title': "Something's not right. Please try again later.",
  'gifting.success.header': 'Your Embark RESP gift has been sent!',
  'gifting.success.body': 'You will receive a receipt in your inbox, and we will notify the Embark customer that your gift is on the way!',
  'gifting.success.button': 'Done'
};
